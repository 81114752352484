import { RoleId } from '@wix/members-domain-ts';

import { origin } from '../constants/bi';
import { Nullable } from './util';

type Origin = typeof origin;

type BIBoolean = 0 | 1;

export enum BIEvent {
  AuthorEdited = 366,
  ShowLeaveCommunityModal = 802,
  LeftCommunity = 803,
  ShowJoinCommunityModal = 804,
  JoinedCommunity = 805,
  FollowOrUnfollow = 810,
  RoleActionClicked = 811,
  AppLoaded = 814,
  ProfileEdited = 815,
  MembersChatOpened = 817,
  ProfileLayoutChanged = 820,
  ProfileDesignEdited = 828,
  ShowFFToggle = 901,
  AppSettingsLoaded = 902,
}

export type CommonBIEvent = {
  app_id: string;
  biToken: string;
  instance_id: string;
  is_social: BIBoolean;
  role: string;
  _: number;
};

export type AuthorEditedBIEvent = CommonBIEvent & {
  author_id: string;
  author_role: string;
  cover_changed: BIBoolean;
  has_cover: BIBoolean;
  has_picture: BIBoolean;
  new_author_name: string;
  new_author_title: Nullable<string>;
  origin: 'members area profile';
  was_name_changed: BIBoolean;
  was_picture_changed: BIBoolean;
};

export enum ProfileActionType {
  EDIT_PROFILE = 'edit_profile_3_dots',
  SHARE_PROFILE = 'share_profile_3_dots',
  VIEW_PUBLIC_PROFILE = 'view_public_profile',
  EXIT_VIEW_PUBLIC_PROFILE = 'exit_preview_public_profile',
}

export type RoleActionClickedBIEvent = CommonBIEvent & {
  member_chosen?: string;
  action_type: RoleId | ProfileActionType;
};

export type FollowOrUnFollowBIEvent = CommonBIEvent & {
  is_followed: BIBoolean;
  member_followed: string;
  origin: Origin;
};

export type AppLoadedBIEvent = CommonBIEvent & {
  origin: Origin;
  page_name: Origin;
  widget_name: Origin;
  widget_id: string;
  member_id?: string;
  currentPageId?: string;
};

export type ProfileChangedBIEvent = CommonBIEvent & {
  photo_changed: BIBoolean;
  name_changed: BIBoolean;
  cover_photo_change: BIBoolean;
  titleChanged: BIBoolean;
};

export type MembersChatOpenedBIEvent = CommonBIEvent & {
  origin: Origin;
  messaged_member_id: string;
};

export type ProfileLayoutChangedBIEvent = CommonBIEvent & {
  layoutType: 'horizontal' | 'vertical';
};

export type ProfileDesignEditedBIEvent = CommonBIEvent & {
  colorChanged?: BIBoolean;
  coverChanged?: BIBoolean;
};

export type ShowFFToggleBIEvent = CommonBIEvent & {
  origin: Origin;
};

export type AppSettingsLoadedBIEvent = CommonBIEvent & {
  origin: Origin;
  settings_changed: 0;
};
